import React, { useState, useRef, useEffect } from 'react';
import { useDrag, useDrop, useDragLayer, XYCoord } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import PropTypes from 'prop-types';
import { StopCauseIcon } from './RoundIcons';

const DraggableCauseIcon = ({
  cause, index, moveCause, updateCauses, onClick,
}) => {
  const ref = useRef<HTMLInputElement>(null);
  const [initialOffset, setInitialOffset] = useState<XYCoord>();
  const [show, setShow] = useState(false);

  const [, drop] = useDrop({
    accept: 'cause',
    drop() {
      updateCauses();
      setInitialOffset(undefined);
      setShow(false);
    },
    hover(item: { type: string, id: string, index: number }, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current && ref.current.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = (clientOffset ? clientOffset.y : 0) - hoverBoundingRect.top;
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY / 6) {
        return;
      }

      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY * 6) {
        return;
      }
      moveCause(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  let wasDragging = false;
  const [{ isDragging }, drag, preview] = useDrag({
    item: {
      id: cause.id,
      color: cause.color,
      icon: cause.icon,
      index,
    },
    type: 'cause',
    collect: monitor => {
      const isCurrentlyDragging = monitor.isDragging();
      if (isCurrentlyDragging && !wasDragging) {
        setInitialOffset(monitor.getInitialSourceClientOffset() || { x: 0, y: 0 });
        setShow(true);
      }
      wasDragging = isCurrentlyDragging;
      return { isDragging: isCurrentlyDragging };
    },
    previewOptions: {
      captureDraggingState: true,
    },
  });

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
  }, []);

  drag(drop(ref));

  const getItemStyles = currentOffset => {
    if (!initialOffset || !currentOffset) {
      return {
        display: 'none',
      };
    }
    const { x: initialX, y: initialY } = initialOffset;
    const { x, y } = currentOffset;
    const transform = `translate(${x - initialX}px, ${y - initialY}px)`;

    return {
      transform,
      WebkitTransform: transform,
    };
  };

  const customDragLayer = () => {
    const { item, currentOffset } = useDragLayer(monitor => ({
      item: monitor.getItem(),
      currentOffset: monitor.getSourceClientOffset(),
    }));
    if (!isDragging || !show) {
      return null;
    }
    return (
      <div className="causeIconPreview" style={getItemStyles(currentOffset)}>
        <StopCauseIcon
          option={{ icon: item?.icon, color: item?.color }}
        />
      </div>
    );
  };

  return (
    <>
      <div ref={ref}>
        <StopCauseIcon
          option={{ ...cause, badgeCount: cause.subMenu?.length }}
          onClick={() => onClick(cause)}
        />
      </div>
      {customDragLayer()}
    </>
  );
};

DraggableCauseIcon.propTypes = {
  cause: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  moveCause: PropTypes.func.isRequired,
  updateCauses: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default DraggableCauseIcon;
