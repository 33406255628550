import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { ButtonGroup, Checkbox, Select } from '@intelligenceindustrielle/react-ui-components';
import { RootState } from '~services/store';
import { CheckboxToggle, FontAwesome } from '~UI/index';
import { sortArray } from '~utils/sort';

const FormMachineState = ({ isTile, navSelected, selectedObject }) => {
  const { t } = useTranslation();

  const dashboards = useSelector((state: RootState) => state.dashboards.dashboards);
  const machines = useSelector((state: RootState) => state.machines);

  const [checkboxDashboardEnabled, setCheckboxDashboardEnabled] = useState(selectedObject.checkboxDashboard || false);
  const [checkboxPopupEnabled, setCheckboxPopupEnabled] = useState(selectedObject.checkboxPopup || false);
  const [selectedFlag, setSelectedFlag] = useState(selectedObject.showPopupAtEventStatus || 'OFF');
  const [machineId, setMachineId] = useState(selectedObject.machineId);
  const machine = machines.find(m => m.id === machineId);
  const machineParams = machine ? Object.keys(machine.params || {}) : [];
  const machineOptions = sortArray('alphabetically', machines, 'name').map(m => ({ label: m.name, value: m.id }));
  const dashboardOptions = sortArray('alphabetically', dashboards, 'name').map(d => ({ label: d.name, value: d.id }));
  const [shownMachineParams, setShownMachineParams] = useState(selectedObject.shownMachineParams || []);
  const [readOnlyMachineParams, setReadOnlyMachineParams] = useState(selectedObject.readOnlyMachineParams || []);
  const [hasPerformanceCausePopup, setHasPerformanceCausePopup] = useState(selectedObject.showPerformanceCausePopupAtEventStatus === 'START'
  || selectedObject.showPerformanceCausePopupAtEventStatus === 'END');
  const [
    showPerformanceCausePopupAtEventStatus,
    setShowPerformanceCausePopupAtEventStatus,
  ] = useState(selectedObject.showPerformanceCausePopupAtEventStatus || 'NONE');
  const [
    showDefectCausePopupAtEventStatus,
    setShowDefectCausePopupAtEventStatus,
  ] = useState(selectedObject.showDefectCausePopupAtEventStatus || 'NONE');

  const handleChangeCheckboxDashboard = () => {
    setCheckboxDashboardEnabled(prevState => !prevState);
  };

  const handleFlagSelection = newValue => {
    setSelectedFlag(newValue);
  };

  const handleChangeCheckboxPopup = () => {
    setCheckboxPopupEnabled(prevState => !prevState);
  };

  const handleChangePerformanceCheckboxPopup = () => {
    setHasPerformanceCausePopup(prevState => {
      setShowPerformanceCausePopupAtEventStatus(!prevState ? 'START' : 'NONE');
      return !prevState;
    });
  };

  const handleChangePerformanceCausePopup = newValue => {
    setShowPerformanceCausePopupAtEventStatus(newValue);
  };

  const handleChangeDefectCheckboxPopup = () => {
    setShowDefectCausePopupAtEventStatus(prevState => {
      if (prevState === 'SCRAP') {
        return 'NONE';
      }
      return 'SCRAP';
    });
  };

  function getParamsToArray(value, name) {
    if (value) {
      setShownMachineParams([...shownMachineParams, name]);
    } else {
      setShownMachineParams(shownMachineParams.filter(p => p !== name));
      setReadOnlyMachineParams(readOnlyMachineParams.filter(p => p !== name));
    }
  }

  function getReadOnlyParamsToArray({ target: { value } }, name) {
    const newReadOnlyParams = value ? [...readOnlyMachineParams, name]
      : readOnlyMachineParams.filter(p => p !== name);
    setReadOnlyMachineParams(newReadOnlyParams);
  }

  const stopCauseButtons = [
    { label: t('startStop'), value: 'OFF' },
    { label: t('endStop'), value: 'ON' },
    { label: t('startAndEndStop'), value: 'BOTH' },
  ];
  const performanceButtons = [
    { label: t('startDrop'), value: 'START' },
    { label: t('endDrop'), value: 'END' },
  ];

  return (
    <div className="Form">
      <div
        className={classnames({
          hiddenDiv: navSelected === 'advanced',
        })}
      >
        <div className="inputTitle">{t('machine')}</div>
        <Select
          name="machineId"
          options={machineOptions}
          value={machineId}
          onChange={setMachineId}
          placeholder={t('select')}
        />
        <div className="inputTitle">
          {t('showStopCausePopup')}
              &nbsp;
          <CheckboxToggle
            name="checkboxPopup"
            controlledCheck={checkboxPopupEnabled}
            onChange={handleChangeCheckboxPopup}
          />
        </div>
        <div className={`toggleBlock ${checkboxPopupEnabled ? 'showToggledBlockPopup' : 'hideToggledBlock'}`}>
          <input type="hidden" name="showPopupAtEventStatus" value={selectedFlag} />
          <ButtonGroup
            buttons={stopCauseButtons}
            onChange={handleFlagSelection}
            value={selectedFlag}
          />
        </div>
        <div className="inputTitle">
          {t('showPerformanceCausePopup')}
              &nbsp;
          <CheckboxToggle
            controlledCheck={hasPerformanceCausePopup}
            onChange={handleChangePerformanceCheckboxPopup}
          />
        </div>
        <div className={`toggleBlock ${hasPerformanceCausePopup ? 'showToggledBlockPopup' : 'hideToggledBlock'}`}>
          <input type="hidden" name="showPerformanceCausePopupAtEventStatus" value={showPerformanceCausePopupAtEventStatus} />
          <ButtonGroup
            buttons={performanceButtons}
            onChange={handleChangePerformanceCausePopup}
            value={showPerformanceCausePopupAtEventStatus}
          />
        </div>
        <div className="inputTitle">
          {t('showDefectCausePopup')}
              &nbsp;
          <input type="hidden" name="showDefectCausePopupAtEventStatus" value={showDefectCausePopupAtEventStatus} />
          <CheckboxToggle
            controlledCheck={showDefectCausePopupAtEventStatus && showDefectCausePopupAtEventStatus !== 'NONE'}
            onChange={handleChangeDefectCheckboxPopup}
          />
        </div>
        <div className="inputTitle">
          {t('displayMachineParams')}
        </div>
        <div data-testid="FormMachineState-machineParameters">
          {machine && machineParams.length === 0 && (
            <>
              <FontAwesome icon="info-circle" />
              {` ${t('noMachineParams')}`}
            </>
          )}
          {machineParams.map(param => (
            <div className="multipleSelectionInput" key={`div_${param}`}>
              <Checkbox
                checked={!!shownMachineParams.find(p => p === param)}
                key={`input_${param}`}
                label={t(`${param}`)}
                onChange={value => getParamsToArray(value, param)}
                value={param}
              />
              <input type="hidden" name="array:shownMachineParams" value={shownMachineParams} />
              {isTile && (
                <div>
                  <div className="input-subtitle">
                    {t('readOnly')}
                    &nbsp;
                    <CheckboxToggle
                      controlledCheck={
                        !!readOnlyMachineParams.find(p => p === param)
                        || (param === 'skuNumber' && machineParams.includes('operation'))
                      }
                      onChange={e => getReadOnlyParamsToArray(e, param)}
                      readOnly={
                        !shownMachineParams.find(p => p === param)
                        || (param === 'skuNumber' && machineParams.includes('operation'))
                      }
                      small
                    />
                  </div>
                  <input type="hidden" name="array:readOnlyMachineParams" value={readOnlyMachineParams} />
                </div>
              )}
            </div>
          ))}
        </div>
      </div>

      <div
        className={classnames({
          hiddenDiv: navSelected === 'parameters',
        })}
      >
        {isTile && (
          <div className="inputTitle">
            {t('btnToDashboard')}
            &nbsp;
            <CheckboxToggle
              name="checkboxDashboard"
              controlledCheck={checkboxDashboardEnabled}
              onChange={handleChangeCheckboxDashboard}
            />
          </div>
        )}
        {isTile && checkboxDashboardEnabled && (
          <div data-testid="FormMachineState-dashboardSelect">
            <div className="inputTitle">{t('dashboard')}</div>
            <Select
              name="dashboard"
              defaultValue={selectedObject.dashboard}
              options={dashboardOptions}
              placeholder={t('select')}
            />
          </div>
        )}
        <div className="inputTitle">
          {t('showTimer')}
          &nbsp;
          <CheckboxToggle
            name="showTimer"
            defaultChecked={typeof selectedObject.showTimer === 'boolean' ? selectedObject.showTimer : true}
          />
        </div>
        <div className="inputTitle">
          {t('showStopCause')}
          &nbsp;
          <CheckboxToggle
            name="showStopCause"
            defaultChecked={typeof selectedObject.showStopCause === 'boolean' ? selectedObject.showStopCause : false}
          />
        </div>
        <div className="inputTitle">
          {t('showOperatorPopupAtShiftChange')}
          &nbsp;
          <CheckboxToggle
            name="showOperatorPopupAtShiftChange"
            defaultChecked={selectedObject.showOperatorPopupAtShiftChange}
          />
        </div>
        <div className="inputTitle">
          {t('mandatoryStopCause')}
          &nbsp;
          <CheckboxToggle
            name="mandatoryStopCause"
            defaultChecked={selectedObject.mandatoryStopCause}
          />
        </div>
      </div>
    </div>
  );
};

FormMachineState.propTypes = {
  isTile: PropTypes.bool,
  navSelected: PropTypes.string,
  selectedObject: PropTypes.shape({
    checkboxDashboard: PropTypes.bool,
    checkboxPopup: PropTypes.bool,
    dashboard: PropTypes.string,
    machineId: PropTypes.string,
    shownMachineParams: PropTypes.arrayOf(PropTypes.string),
    readOnlyMachineParams: PropTypes.arrayOf(PropTypes.string),
    showDefectCausePopupAtEventStatus: PropTypes.string,
    showOperatorPopupAtShiftChange: PropTypes.bool,
    showPerformanceCausePopupAtEventStatus: PropTypes.string,
    showPopupAtEventStatus: PropTypes.string,
    showStopCause: PropTypes.bool,
    showTimer: PropTypes.bool,
    mandatoryStopCause: PropTypes.bool,
  }),
};
FormMachineState.defaultProps = {
  isTile: false,
  navSelected: 'parameters',
  selectedObject: {},
};

export { FormMachineState };
