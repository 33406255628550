import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import IconButtonComponent from './IconButtonComponent';

const propTypes = {
  // The Icons should have only one of the following props.
  // They have 'Modal' if a modal should pop when they are clicked.
  // They have 'onClick' otherwise.
  Modal: PropTypes.shape({
    Component: PropTypes.oneOfType([
      PropTypes.elementType,
      PropTypes.func,
    ]),
    props: PropTypes.shape({}),
  }).isRequired,
  onClick: PropTypes.func.isRequired,
};

const SettingsIcon = ({ Modal = null, onClick = null }) => {
  const { t } = useTranslation();

  return (
    <IconButtonComponent
      Modal={Modal}
      onClick={onClick}
      icon="cog"
      className="settingsButton"
      tooltipLabel={t('edit')}
      tooltipPlace="left"
    />
  );
};

const DuplicateIcon = ({ Modal = null, onClick = null }) => {
  const { t } = useTranslation();

  return (
    <IconButtonComponent
      Modal={Modal}
      onClick={onClick}
      icon="clone"
      className="duplicateButton"
      tooltipLabel={t('duplicate')}
      tooltipPlace="left"
    />
  );
};

const DuplicateToIcon = ({ Modal = null, onClick = null }) => {
  const { t } = useTranslation();

  return (
    <IconButtonComponent
      Modal={Modal}
      onClick={onClick}
      icon="share-square"
      className="duplicateButton"
      tooltipLabel={t('duplicateTo')}
      tooltipPlace="left"
    />
  );
};

const EditIcon = ({ Modal = null, onClick = null, tooltipOff = false, disabled = false }) => {
  const { t } = useTranslation();

  return (
    <IconButtonComponent
      Modal={Modal}
      onClick={onClick}
      icon="edit"
      className={disabled ? 'editButtonDisabled' : 'editButton'}
      tooltipLabel={tooltipOff ? null : t('edit')}
      tooltipPlace="left"
    />
  );
};

const DeleteIcon = ({ Modal = null, onClick = null, tooltipOff = false }) => {
  const { t } = useTranslation();

  return (
    <IconButtonComponent
      Modal={Modal}
      onClick={onClick}
      icon="trash"
      className="deleteButton"
      tooltipLabel={tooltipOff ? null : t('delete')}
      tooltipPlace="right"
    />
  );
};

const BanIcon = ({ Modal = null, onClick = null }) => {
  const { t } = useTranslation();

  return (
    <IconButtonComponent
      Modal={Modal}
      onClick={onClick}
      icon="ban"
      className="banButton"
      tooltipLabel={t('stopped')}
      tooltipPlace="right"
    />
  );
};

const CommentIcon = ({ Modal = null, onClick = null, className = null }) => {
  const { t } = useTranslation();

  return (
    <IconButtonComponent
      Modal={Modal}
      onClick={onClick}
      icon="comment"
      className={className || 'commentButton'}
      tooltipLabel={t('comments')}
      tooltipPlace="right"
    />
  );
};

const PlusIcon = ({ Modal = null, onClick = null }) => {
  const { t } = useTranslation();

  return (
    <IconButtonComponent
      Modal={Modal}
      onClick={onClick}
      icon="plus"
      className="plusButton"
      tooltipLabel={t('add')}
      tooltipPlace="right"
    />
  );
};

const ArrowsMoveIcon = ({ onClick = null, handleArrowsIsMoving = false }) => {
  const { t } = useTranslation();

  return (
    <IconButtonComponent
      onClick={onClick}
      icon={handleArrowsIsMoving ? 'arrows-alt' : 'arrows'}
      className="moveButton"
      tooltipLabel={t('move')}
      tooltipPlace="right"
    />
  );
};

SettingsIcon.propTypes = propTypes;
DuplicateIcon.propTypes = propTypes;
DuplicateToIcon.propTypes = propTypes;
EditIcon.propTypes = {
  ...propTypes,
  tooltipOff: PropTypes.bool.isRequired,
};
DeleteIcon.propTypes = {
  ...propTypes,
  tooltipOff: PropTypes.bool.isRequired,
};
BanIcon.propTypes = propTypes;
CommentIcon.propTypes = {
  ...propTypes,
  className: PropTypes.string.isRequired,
};
PlusIcon.propTypes = propTypes;
ArrowsMoveIcon.propTypes = {
  ...propTypes,
  handleArrowsIsMoving: PropTypes.bool.isRequired,
};

export {
  EditIcon,
  SettingsIcon,
  DeleteIcon,
  BanIcon,
  CommentIcon,
  DuplicateIcon,
  DuplicateToIcon,
  ArrowsMoveIcon,
  PlusIcon,
};
